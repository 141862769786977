import birds from './img/birds2.jpg'
import m3to from './img/m3to-test.jpg'
import laSelva from './img/laSelvaStill.jpg'
import owlDervish from './img/owl-dervish.jpg'
import sfxGame from './img/sfx-game.jpg'
import humHuman from './img/humHuman.jpg'
import surprise from './img/surprise.jpg'
import soundOfFire from './img/soundOfFire.jpg'
import silencePlease from './img/silencePlease.jpg'
import fleshBlood from './img/fleshBloodFarGlass.jpg'
import fmj from './img/fmj_vert_from-stairs.jpg'
import libraryBook from './img/library_book_small.jpg'
import roomTone from './img/09-rt-drawing.jpg'
import grassGrowing from './img/grassgrowing1.jpg'
// import humHuman from './img/littleGirl.jpg'
import nimmoi from './img/NIMMOI.jpg'
import weWillLive from './img/weWillLiveMosque.jpg'
import wan from './img/wanTooSmall.jpg'
import lrs from './img/lrs.jpg'
import baduEyes from './img/badu-eyes.jpg'
import silenceFarm from './img/silence-farm.jpg'
// './img/'
// './img/'
// './img/'
// './img/'

export const images = {
    silenceFarm,
    lrs,
    baduEyes,
    birds,
    m3to,
    laSelva,
    owlDervish,
    sfxGame,
    humHuman,
    surprise,
    soundOfFire,
    silencePlease,
    fleshBlood,
    fmj,
    libraryBook,
    roomTone,
    grassGrowing,
    nimmoi,
    weWillLive,
    wan,
    // nicasio,

    
}